@font-face
{
  font-family: 'montserrat';
  font-style: normal;
  src: url('../src/assets/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face
{
  font-family: 'montserrat-extrabold';
  font-style: normal;
  src: url('../src/assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    font-family: 'montserrat-extrabold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'montserrat-extrabold';
}

body button{
  font-family: 'montserrat-extrabold';
  font-size: 10pt;
  height: 40px;
  color:white;
  background-color: #d32f2f;
}

body h5,h6,h7{
  font-family: 'montserrat-extrabold';
}

body p{
  font-family: 'montserrat-extrabold';
}

.accordion-button
{
  color: black !important;
}

body {
  padding-top: 56px; /* Ajusta según la altura del navbar */
  overflow-y: hidden !important;
  height: 90vh;
}